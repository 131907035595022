<template>
  <!--封面資訊  =====滑動gallery、前後按鈕==== -->
  <div
    id="carouselExampleIndicators"
    class="carousel slide carousel-fade"
    data-bs-ride="carousel"
    data-interval="8000"
  >
    <ol class="carousel-indicators">
      <li
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active"
      ></li>
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"></li>
    </ol>
    <div
      class="carousel-inner"
      style="min-width:100%;height:100vh;margin-top:-9vh"
    >
      <div class="carousel-item active" id="slide_gallery1">
        <video
          autoplay="autoplay"
          loop="loop"
          muted="muted"
          class="d-block w-100"
          style="object-fit: fill;height:100vh;"
        >
          <source
            :src="require('../../assets/images/xgis.mp4')"
            type="video/mp4"
            class="d-block w-100"
          />
        </video>
        <div class="carousel-caption">
          <h5 class="x_title_geo">{{ i18n.$t("x_title_geo") }}</h5>
          <h3 class="x_MakeYourIdeasComeTrue">Make Your Ideas Come True</h3>
          <a
            href="http://www.xgis.com.tw/xgishome/"
            target="_blank"
            class="btn btn-info btn-lg"
            ><span class="x_understandsMore">{{
              i18n.$t("x_understandsMore")
            }}</span></a
          >
          <!--<button data-target="#news" type="button" class="btn btn-info btn-lg btn-target"><span class="x_understandsMore">了解更多</span></button>-->
          <br />
          <button
            type="button"
            class="btn btn-warning"
            v-on:click="setLang('tw')"
          >
            中文版
          </button>
          <button
            type="button"
            class="btn btn-danger"
            v-on:click="setLang('en')"
          >
            English
          </button>
        </div>
      </div>
      <div class="carousel-item " id="slide_gallery2">
        <img
          :src="require('../../assets/images/streetlight/header.jpg')"
          class="d-block w-100"
          style="object-fit: fill; height: 100vh"
        />
        <div class="carousel-caption">
            <h5 class="x_title_st">{{ i18n.$t("x_title_st") }}</h5>
            <h3 class="x_title_st_content">
                {{ i18n.$t("x_title_st_content") }}
            </h3>
            <router-link class="btn btn-success btn-lg"
                         to="./streetlight#streetlight">
                <span class="x_understandsMore">
                    {{
                          i18n.$t("x_understandsMore")
                    }}
                </span>
            </router-link>
        </div>
      </div>
      <div class="carousel-item " id="slide_gallery3">
        <img
          :src="require('../../assets/images/title_ele.jpg')"
          class="d-block w-100"
          style="object-fit: fill; height: 100vh"
        />
        <div class="carousel-caption">
          <h5 class="x_title_ele">{{ i18n.$t("x_title_ele") }}</h5>
          <h3 class="x_title_ele_content">
            {{ i18n.$t("x_title_ele_content") }}
          </h3>
        </div>
      </div>
      <div class="carousel-item" id="slide_gallery4">
        <img
          :src="require('../../assets/images/section-project/project-03.jpg')"
          class="d-block w-100"
          style="object-fit: fill; height: 100vh"
        />
        <div class="carousel-caption">
          <h5 class="x_title_etec">{{ i18n.$t("x_title_etec") }}</h5>
          <h3 class="x_title_etec_content">
            {{ i18n.$t("x_title_etec_content") }}
          </h3>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </a>
  </div>
</template>

<script>
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "CoverInformation",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    return { i18n, setLang };
  },
};
</script>

<style scoped></style>
