<template>
  <div id="aboutBody">
    <!-- Navigation -->
    <nav
      class="navbar navbar-dark  fixed-top navbar-expand-xl navshow"
      role="navigation"
    >
      <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->

        <a href="../" class="btn-target " title="雍盛科技">
          <img
            :src="require('@/assets/images/top.jpg')"
            width="50"
            height="50"
          />
          <div class="navbar-brand" style="display:inline-block">
            <span class="x_companyName">{{ i18n.$t("x_companyName") }}</span>
          </div>
        </a>
        <button
          type="button"
          class="navbar-toggler"
          data-toggle="collapse"
          data-target="#bs-example-navbar-collapse-1"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="navbar-nav ms-auto">
            <li class="btn-target nav-item">
              <router-link class="btn-target nav-link" to="../"
                ><span class="x_Home">{{
                  i18n.$t("x_Home")
                }}</span></router-link
              >
            </li>
            <li class="btn-target nav-item">
              <a class="btn-target nav-link" href="#about"
                ><span class="x_About">{{ i18n.$t("x_About") }}</span></a
              >
            </li>
            <li class="nav-item d-none d-sm-none d-xl-block ms-5">
              <div class="btn-group dropdown">
                <button
                  type="button"
                  class="btn btn-info btn-secondary dropdown-toggle"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-globe-africa"></i
                  ><span class="x_Language">{{ i18n.$t("x_Language") }}</span>
                </button>
                <ul class="dropdown-menu bg-info">
                  <li>
                    <a
                      class="dropdown-item"
                      value="tw"
                      v-on:click="setLang('tw')"
                      ><span class="x_TraditionalChinese">繁體中文</span></a
                    >
                  </li>

                  <!--                <div class="dropdown-divider"></div>-->
                  <li>
                    <a
                      class="dropdown-item"
                      value="en"
                      v-on:click="setLang('en')"
                      ><span class="x_English">English</span></a
                    >
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item d-block d-xl-none ms-0 dropdown">
              <div class="dropdown-divider"></div>
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <i class="fas fa-globe-africa"></i
                ><span class="x_Language">{{ i18n.$t("x_Language") }}</span>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" value="tw" v-on:click="setLang('tw')"
                  ><span class="x_TraditionalChinese">繁體中文</span></a
                >
                <a class="dropdown-item" value="en" v-on:click="setLang('en')"
                  ><span class="x_English">English</span></a
                >
              </div>
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container -->
    </nav>

    <!-- Main -->
    <div class="container">
      <!-- 內容 -->
      <div id="content-wrapper_1">
        <!-- 關於雍盛 -->
        <div id="main_title_1" class="row">
          <div class="col-lg-12">
            <h1 class="title color-dark">
              <span class="x_AboutYungShingCooperation">{{
                i18n.$t("x_AboutYungShingCooperation")
              }}</span>
            </h1>
            <img :src="require('@/assets/images/building3d.jpg')" />
          </div>
        </div>

        <!-- 歷史簡介 -->
        <div id="about" class="row">
          <div id="history" class="col-lg-12">
            <h2>
              <i class="fas fa-globe-asia fa-sm"></i>&nbsp;<span
                class="x_HistoryBrief"
                >{{ i18n.$t("x_HistoryBrief") }}</span
              >
            </h2>
            <img :src="require('@/assets/images/574.jpg')" alt="辦公室環境" />
            <p>
              <span class="x_HistoryBrief_content">{{
                i18n.$t("x_HistoryBrief_content")
              }}</span>
            </p>
          </div>
        </div>

        <!-- 人員編制 -->
        <div id="staffing" class="row">
          <div id="history" class="col-lg-12">
            <h2>
              <i class="fas fa-globe-asia fa-sm"></i>&nbsp;<span
                class="x_OurOrganization"
                >{{ i18n.$t("x_OurOrganization") }}</span
              >
            </h2>
            <div class="row">
              <div class="col-lg-6">
                <div class="GroupImg center-block">
                  <img :src="require('@/assets/images/icon-04.png')" />
                </div>
                <div class="GroupContent text-center">
                  <h4>
                    <span class="x_SoftwareDD">{{
                      i18n.$t("x_SoftwareDD")
                    }}</span>
                  </h4>
                  <p>
                    <span class="x_SoftwareDD_content">{{
                      i18n.$t("x_SoftwareDD_content")
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="GroupImg center-block">
                  <img :src="require('@/assets/images/icon-03.png')" />
                </div>
                <div class="GroupContent text-center">
                  <h4>
                    <span class="x_SystemMD">{{ i18n.$t("x_SystemMD") }}</span>
                  </h4>
                  <p>
                    <span class="x_SystemMD_content">{{
                      i18n.$t("x_SystemMD_content")
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 經營理念 -->
        <div id="Business_philosophy" class="row">
          <div id="philosophy" class="col-lg-12">
            <h2>
              <i class="fas fa-globe-asia fa-sm"></i>&nbsp;<span
                class="x_CustomerOriented"
                >{{ i18n.$t("x_CustomerOriented") }}</span
              >
            </h2>
            <p>
              <span class="x_CustomerOriented_content">{{
                i18n.$t("x_CustomerOriented_content")
              }}</span>
            </p>
          </div>
        </div>

        <!-- 公司特點 -->
        <div id="features" class="row">
          <div class="col-lg-12">
            <h4>
              <span class="x_CompanyCulture">{{
                i18n.$t("x_CompanyCulture")
              }}</span>
            </h4>
            <ul>
              <li>
                <span class="x_Customer">{{ i18n.$t("x_Customer") }}</span>
              </li>
              <li>
                <span class="x_PlainPractical">{{
                  i18n.$t("x_PlainPractical")
                }}</span>
              </li>
              <li>
                <span class="x_WisdomContribut">{{
                  i18n.$t("x_WisdomContribut")
                }}</span>
              </li>
              <li>
                <span class="x_Humanity">{{ i18n.$t("x_Humanity") }}</span>
              </li>
            </ul>
          </div>
          <div class="col-lg-12">
            <h4>
              <span class="x_Expertise">{{ i18n.$t("x_Expertise") }}</span>
            </h4>
            <ul>
              <li>
                <span class="x_Integrate">{{ i18n.$t("x_Integrate") }}</span>
              </li>
              <li>
                <span class="x_Integrate_XGIS">{{
                  i18n.$t("x_Integrate_XGIS")
                }}</span>
              </li>
              <li>
                <span class="x_Integrate_network">{{
                  i18n.$t("x_Integrate_network")
                }}</span>
              </li>
              <li>
                <span class="x_Integrate_functions">{{
                  i18n.$t("x_Integrate_functions")
                }}</span>
              </li>
              <li>
                <span class="x_Integrate_application">{{
                  i18n.$t("x_Integrate_application")
                }}</span>
              </li>
            </ul>
          </div>
          <div class="col-lg-12">
            <h4>
              <span class="x_CompanyCharacteristics">{{
                i18n.$t("x_CompanyCharacteristics")
              }}</span>
            </h4>
            <ul>
              <li>
                <span class="x_Advanced">{{ i18n.$t("x_Advanced") }}</span>
              </li>
              <li>
                <span class="x_PreciseProject">{{
                  i18n.$t("x_PreciseProject")
                }}</span>
              </li>
              <li>
                <span class="x_SolidNetwork">{{
                  i18n.$t("x_SolidNetwork")
                }}</span>
              </li>
              <li>
                <span class="x_HighEfficient">{{
                  i18n.$t("x_HighEfficient")
                }}</span>
              </li>
              <li>
                <span class="x_HaveExtensive">{{
                  i18n.$t("x_HaveExtensive")
                }}</span>
              </li>
              <li>
                <span class="x_MutualTechnology">{{
                  i18n.$t("x_MutualTechnology")
                }}</span>
              </li>
            </ul>
          </div>
          <div class="col-lg-12">
            <h4>
              <span class="x_OurPromise">{{ i18n.$t("x_OurPromise") }}</span>
            </h4>
            <ul>
              <li>
                <span class="x_CompletedProducts">{{
                  i18n.$t("x_CompletedProducts")
                }}</span>
              </li>
              <li>
                <span class="x_StableQuality">{{
                  i18n.$t("x_StableQuality")
                }}</span>
              </li>
              <li>
                <span class="x_PriceReasonable">{{
                  i18n.$t("x_PriceReasonable")
                }}</span>
              </li>
              <li>
                <span class="x_ConsummateServices">{{
                  i18n.$t("x_ConsummateServices")
                }}</span>
              </li>
              <li>
                <span class="x_BrandImage">{{ i18n.$t("x_BrandImage") }}</span>
              </li>
              <li>
                <span class="x_WinWinPolicy">{{
                  i18n.$t("x_WinWinPolicy")
                }}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- 客戶導向的服務體系 -->
        <div id="customer_service">
          <div class="row title-group">
            <div class="col-lg-12">
              <h2>
                <i class="fas fa-globe-asia fa-sm"></i>&nbsp;<span
                  class="x_ServiceSystem "
                  >{{ i18n.$t("x_ServiceSystem") }}</span
                >
              </h2>
              <p>
                <span class="x_ServiceSystem_content">{{
                  i18n.$t("x_ServiceSystem_content")
                }}</span>
              </p>
              <p>
                <span class="x_ServiceSystem_content_text">{{
                  i18n.$t("x_ServiceSystem_content_text")
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 頁尾 -->
    <div id="foot">
      <div class="container">
        <div class="row title-group">
          <div class="col-lg-12">
            <h4>
              <span class="x_YuShengTechnology">{{
                i18n.$t("x_YuShengTechnology")
              }}</span>
            </h4>
            <p>
              <span class="x_Copyright">{{ i18n.$t("x_Copyright") }}</span>
            </p>
            <p>
              <span class="x_Address">{{ i18n.$t("x_Address") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "CoverInformation",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    return { i18n, setLang };
  },
};
</script>

<style scoped src="../assets/css/aboutset.css">
.lightbox {
  display: none !important;
}
</style>
