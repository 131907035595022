<template>
  <section id="sign">
    <div class="container">
      <div class="row title-group">
        <div class="col-lg-12">
          <h2 class="title color-angle">
            <span class="x_ServiceApplication">{{
              i18n.$t("x_ServiceApplication")
            }}</span>
          </h2>
          <p class="sub-title color-angle">
            <span class="x_LeaveYourContact">{{
              i18n.$t("x_LeaveYourContact")
            }}</span>
          </p>
          <form
            class="signForm"
            @submit="SaveData"
            style="width:100%;margin:20px 0px; text-align:center"
          >
            <div class="v-group" style="margin-top:2.5rem">
              <input
                type="email"
                class=" p_Email"
                id="Email"
                name="editdata_mail"
                v-model="ItemData.mail"
                required
              />
              <label for="Email" class="x_Email">{{
                i18n.$t("x_Email")
              }}</label>
            </div>
            <div class="v-group">
              <input
                type="text"
                class=" p_NickName"
                id="NickName"
                name="editdata_name"
                v-model="ItemData.name"
                required
              />
              <label for="NickName" class="x_NickName">{{
                i18n.$t("x_NickName")
              }}</label>
            </div>
            <div class="v-group" style="margin-bottom:-1rem">
              <input
                type="text"
                class=" p_Title"
                id="Title"
                name="editdata_title"
                v-model="ItemData.title"
                required
              />
              <label for="Title" class="x_Title">{{
                i18n.$t("x_Title")
              }}</label>
            </div>
            <div class="v-group">
              <label for="Contents" class="x_Contents v-group-label-paste">{{
                i18n.$t("x_Contents")
              }}</label>
              <textarea
                class=" p_Contents"
                id="Contents"
                name="editdata_context"
                v-model="ItemData.context"
                required
              ></textarea>
            </div>
            <div class="v-group">
              <label for="ValidKey" class="x_VaildKey v-group-label-paste">{{
                i18n.$t("x_VaildKey")
              }}</label>
              <input
                type="text"
                class=" p_VaildKey"
                id="ValidKey"
                name="editdata_CheckVaildKey"
                v-model="ItemData.CheckVaildKey"
                required
              />
              <div class="form-inline" style="">
                <img
                  id="imgKey"
                  style="width: 100px; height: 34px; display: flex"
                />
                <a title="Refresh" @click="ReGetValidKey()"
                  ><i class="fas fa-redo" style="color:white"></i>
                </a>
              </div>
            </div>

            <input type="hidden" id="myip" :value="ip" />
            <button class="btn btn-info" id="Index_button">
              <span class="x_Send">{{ i18n.$t("x_Send") }}</span>
              <span
                id="mailSpinner"
                class="spinner-border spinner-border-sm"
                style="display:none"
              ></span>
            </button>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 mapbox">
          <img
            :src="require('../../assets/images/building3d.jpg')"
            style="max-width: 100%;"
          />
        </div>
      </div>
      <br />
      <div class="row">
        <div id="footer_map" class="col-lg-12 mapbox">
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="//maps.google.com.tw/maps?f=q&hl=zh-TW&geocode=&q=台中市烏日區高鐵五路156號&z=16&output=embed&t="
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
$(".navbar-toggle").focusout(function() {
  $(".collapse").collapse("hide");
});
import $ from "jquery";
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
import { XDBSettingMixin } from "@/mixins";
import { onMounted, reactive } from "vue";
import DBApi from "@/api/DBApi.js";
export default {
  /*Vue3 mixins寫法改變 目前參考不到*/
  mixins: [XDBSettingMixin],
  name: "ServiceApplication",
  setup() {
    let m_Token="";
    let db = new DBApi("./api/db/",m_Token);
    let TableName = "mail";
    let ItemData = {
      id: "",
      title: "",
      context: "",
      mail: "",
      name: "",
      DateTime: "",
      IP: "",
      CheckVaildKey: "",
      VaildKey: "",
    };
    let PrimKey = "id";
    //取得IP
    const ip = "";
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    const loadVkey = async () => {
      ReGetValidKey();
    };
    const state = reactive({
      VaildKey: [],
    });
    function validForm() {
      try {
      } catch (error) {
        alert(error);
        return false;
      }

      return true;
    }
    function formatToDB() {
      if (!ItemData[PrimKey]) {
        db.async = false;
        db.async = true;
      }
      return true;
    }
    function decodeImage(key, imgDomID) {
      var img = $("#" + imgDomID);
      var len = parseInt(key.substring(0, 2));
      var Vkey = key.substring(2, len + 2);

      if (img.length > 0) {
        img.attr("src", "data:image/jpeg;base64," + key.substring(len + 2));
      } else {
        setTimeout(function() {
          decodeImage(key, imgDomID);
        }, 1000);
      }
      return Vkey;
    }
    async function ReGetValidKey() {
      const data = await fetch(
        "https://ysong.com.tw/ysong/api/db/GetVaildKey"
      ).then((rsp) => rsp.json());
      state.VaildKey = "CheckVaildKey|" + decodeImage(data.Result, "imgKey");
    }
    function SaveData(myForm) {
      console.log(ItemData);
      myForm.preventDefault();
      let isValid = validForm();
      $("#mailSpinner").css("display", "");

      //this.myForm.$valid
      if (isValid) {
        if (!formatToDB()) return;
        if (state.VaildKey != null) {
          ItemData.VaildKey = state.VaildKey;
        }
        var dateTime = new Date();
        ItemData.DateTime =
          dateTime.getFullYear() +
          "-" +
          (dateTime.getMonth() + 1) +
          "-" +
          dateTime.getDate() +
          " " +
          dateTime.getHours() +
          ":" +
          dateTime.getMinutes() +
          ":" +
          dateTime.getSeconds();
        ItemData.IP = $("#myip").val();

        //新版本不論新增或修改，一律都傳primkey;
        db.UpdateTable(TableName, ItemData, "id", (d) => {
          //沒有update權限 找原因中
          if (d.isSuccess == false) {
            $("#mailSpinner").css("display", "none");
            //如果有錯誤
            alert(d.ErrorMsg);
          } else {
            alert("成功");
            /*SentMail(ItemData, function(r) {
              $("#mailSpinner").css("display", "none");
              if (r) {
                alert("我們已收到您的諮詢，感謝您的來信！");
                AddNewData();
              } else {
                alert(
                  "信件無法寄出，請您再試一次。\n也歡迎您直接來電諮詢 04 - 23379448"
                );
              }
              if (state.VaildKey != null) {
                ReGetValidKey();
              }
            });*/
          }
        });
      }
    }
    function AjaxSentMail(mailContent, func) {
      $.ajax({
        type: "POST",
        url: "./Home/SendMailByGmail",
        data: {
          Title: mailContent["title"],
          Context: mailContent["context"],
          Email: mailContent["mail"],
          Name: mailContent["name"],
        },
        success: function(data) {
          func(data);
        },
        error: function(e, r, h) {
          if (e.status !== 520) alert(" (status:" + r + " error:" + h + ")");
        },
      });
    }
    function AddNewData() {
      //this.editdata = new Object;這樣會產生新的綁定物件
      for (let key in ItemData) {
        ItemData[key] = "";
      }
      console.log(ItemData);
    }
    function SentMail(mailContent, func) {
      AjaxSentMail(mailContent, func);
    }
    onMounted(loadVkey);
    return {
      state,
      i18n,
      setLang,
      ip,
      db,
      ReGetValidKey,
      SaveData,
      AjaxSentMail,
      AddNewData,
      SentMail,
      ItemData,
    };
  },
  methods: {},
};
</script>

<style scoped>
.v-group {
  margin-bottom: 1rem;
}
.v-group input,
.v-group textarea {
  color: rgb(250, 250, 250);
  width: 100%;
  background: none;
  border: none;
  border-bottom: solid 2px white;
}
.v-group label {
  font-size: large;
  color: rgb(250, 250, 250);
  display: flex;
  transform: translateY(-2rem);
}
.v-group input:focus + label,
.v-group textarea:focus + label {
  transform: translateY(-3.2rem);
  color: rgb(230, 230, 230);
}
.v-group > label.v-group-label-paste {
  transform: translateY(0);
  color: rgb(230, 230, 230);
}
h2.title {
  margin-top: 50px;
}
p.sub-title {
  margin-top: 30px;
}
.mapbox {
  display: flex;
  justify-content: center;
}
</style>
