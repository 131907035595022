<template>
  <!-- 應用實例 -->
  <section id="type">
    <div class="container">
      <div class="row title-group">
        <div class="col-lg-12">
          <!--<h2 class="title color-dark">地圖應用實例</h2>-->
          <h2 class="title color-dark">
            <span class="x_ApplicationRange">{{
              i18n.$t("x_ApplicationRange")
            }}</span>
          </h2>
          <!--<p class="sub-title color-dust">各式圖表查詢設計，打造符合您需求的專屬地圖。</p>-->
          <p class="sub-title color-dust">
            <span class="x_ApplicationRange_content">{{
              i18n.$t("x_ApplicationRange_content")
            }}</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4  mb-lg-0 mb-3">
          <div id="type-1" class="GroupItem">
            <div class="GroupContent">
              <!--<h3>產業聚落熱區圖</h3>-->
              <!--<p>台灣自行車製造業</p>-->
              <!--<p>主要群聚位置</p>-->
              <p>
                <span class="x_ApplicationRange_1">{{
                  i18n.$t("x_ApplicationRange_1")
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-lg-0 mb-3">
          <div id="type-2" class="GroupItem">
            <div class="GroupContent">
              <!--<h3>統計面量圖查詢</h3>
              <p>新北市板橋區</p>
              <p>表燈用戶用電情形</p>-->
              <p>
                <span class="x_ApplicationRange_2">{{
                  i18n.$t("x_ApplicationRange_2")
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4  ">
          <div id="type-3" class="GroupItem">
            <div class="GroupContent">
              <!--<h3>圖層套疊展示</h3>
              <p>全台工廠家數分布</p>
              <p>與負責人性別比率關係</p>-->
              <p>
                <span class="x_ApplicationRange_3">{{
                  i18n.$t("x_ApplicationRange_3")
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mb-lg-0 mb-3">
          <div id="type-4" class="GroupItem">
            <div class="GroupContent">
              <!--<h3>產業聚落點位圖</h3>
              <p>化學製品業與</p>
              <p>化學材料業工廠分布</p>-->
              <p>
                <span class="x_ApplicationRange_4">{{
                  i18n.$t("x_ApplicationRange_4")
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-lg-0 mb-3">
          <div id="type-5" class="GroupItem">
            <div class="GroupContent">
              <!--<h3>工廠名錄查詢</h3>
              <p>台灣醬油工廠位置</p>
              <p>與基本資料</p>-->
              <p>
                <span class="x_ApplicationRange_5">{{
                  i18n.$t("x_ApplicationRange_5")
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div id="type-6" class="GroupItem">
            <div class="GroupContent">
              <!--<h3>經濟資訊查詢</h3>
              <p>宜蘭市主要產業營收</p>
              <p>與歷年營收狀況</p>-->
              <p>
                <span class="x_ApplicationRange_6">{{
                  i18n.$t("x_ApplicationRange_6")
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mb-lg-0 mb-3">
          <div id="type-7" class="GroupItem">
            <div class="GroupContent">
              <!--<h3>產業聚落熱區圖</h3>
              <p>台灣自行車製造業</p>
              <p>主要群聚位置</p>-->
              <p>
                <span class="x_ApplicationRange_7">{{
                  i18n.$t("x_ApplicationRange_7")
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-lg-0 mb-3">
          <div id="type-8" class="GroupItem">
            <div class="GroupContent">
              <!--<h3>統計面量圖查詢</h3>
              <p>新北市板橋區</p>
              <p>表燈用戶用電情形</p>-->
              <p>
                <span class="x_ApplicationRange_8">{{
                  i18n.$t("x_ApplicationRange_8")
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div id="type-9" class="GroupItem">
            <div class="GroupContent">
              <!--<h3>圖層套疊展示</h3>
              <p>全台工廠家數分布</p>
              <p>與負責人性別比率關係</p>-->
              <p>
                <span class="x_ApplicationRange_9">{{
                  i18n.$t("x_ApplicationRange_9")
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "CoverInformation",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    return { i18n, setLang };
  },
};
</script>

<style scoped></style>
