<template>
  <!-- 服務項目 -->
  <section id="service">
    <div class="container">
      <div class="row title-group ">
        <div class="col-lg-12">
          <h2 class="title color-dark">
            <span class="x_ProfessionalServiceProject">{{
              i18n.$t("x_ProfessionalServiceProject")
            }}</span>
          </h2>
          <p class="sub-title color-dust">
            <span class="x_ProfessionalServiceProject_content">{{
              i18n.$t("x_ProfessionalServiceProject_content")
            }}</span>
          </p>
        </div>
      </div>
      <div class="row justify-content-lg-between text-center">
        <div
          class="col-12 row"
          data-aos="fade-down"
          data-aos-easing="linear"
          v-for="serviceItem in state.ServiceItems"
          :key="serviceItem.imageURL"
        >
          <div class="col-lg-3 col-md-4 col-xs-12 ">
            <img
              class="scale-rotate w-50"
              :src="require('../../assets/images/' + serviceItem.imageURL)"
            />
          </div>
          <div class="col-lg-9 col-md-8 col-xs-12 text-lg-start">
            <h4>
              <span
                :class="serviceItem.title_CSName"
                :id="serviceItem.title_CSName"
              >
                {{ i18n.$t("serviceItems" + serviceItem.id + "_title") }}
              </span>
            </h4>
            <div class="template">
              <p>
                <span
                  :class="serviceItem.content_CSName"
                  :id="serviceItem.content_CSName"
                >
                  {{ i18n.$t("serviceItems" + serviceItem.id + "_content") }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "Service",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    const state = reactive({
      ServiceItems: [],
      ServiceItemsEN: [],
    });
    onMounted(async () => {
      const data = await fetch(
        "https://www.ysong.com.tw/ysong/api/db/SelectTable?Token=&TableName=ServiceItems&Filter=1%3D1&Field=*"
      ).then((rsp) => rsp.json());
      console.log(data.Result);
      state.ServiceItems = data.Result;
    });
    return {
      state,
      i18n,
      setLang,
    };
  },
};
</script>

<style scoped></style>
