<template>
  <!-- 關於雍盛 -->
  <section id="about">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="title color-dark my-5">
            <span class="x_About">{{ i18n.$t("CreateAboutBtn") }}</span>
          </h2>
          <p class="sub-title color-dust"></p>
        </div>
      </div>
      <div class="row" id="aboutbox">
        <div
          class="rideo-wrap col-12 col-xl-4 aos-init aos-animate"
          data-aos="fade-down"
          data-aos-easing="linear"
        >
          <div class="rideo-box">
            <div class="rideo-top" style="border-top:solid 20px #36C9FB;">
              <div class="rideo-bottom" style="border-left:solid 20px #36C9FB;">
                <div
                  class="rideo-right"
                  style="border-bottom: 5px solid #36C9FB;"
                >
                  <div
                    class="rideo-left"
                    style="border-top: 5px solid #36C9FB;"
                  >
                    <div class="memo-text">
                      <div class="rideo-title">
                        <h2>
                          <span class="x_Introduction">{{
                            i18n.$t("x_Introduction")
                          }}</span>
                        </h2>
                      </div>
                      <div class="rideo-content">
                        <p>
                          <span class="x_Introduction_content">{{
                            i18n.$t("x_Introduction_content")
                          }}</span>
                        </p>
                      </div>
                      <router-link
                        class="btn btn-success btn-lg"
                        to="./about#about"
                      >
                        <span class="x_understandsMore">{{
                          i18n.$t("x_understandsMore")
                        }}</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="rideo-wrap col-12 col-xl-4 aos-init aos-animate"
          data-aos="fade-down"
          data-aos-easing="linear"
        >
          <div class="rideo-box">
            <div class="rideo-top" style="border-top:solid 20px #ffca20;">
              <div class="rideo-bottom" style="border-left:solid 20px #ffca20;">
                <div
                  class="rideo-right"
                  style="border-bottom: 5px solid #ffca20;"
                >
                  <div
                    class="rideo-left"
                    style="border-top: 5px solid #ffca20;"
                  >
                    <div class="memo-text">
                      <div class="rideo-title">
                        <h2>
                          <span class="x_Capability">{{
                            i18n.$t("x_Capability")
                          }}</span>
                        </h2>
                      </div>
                      <div class="rideo-content">
                        <p>
                          <span class="x_Capability_content">{{
                            i18n.$t("x_Capability_content")
                          }}</span>
                        </p>
                      </div>
                      <router-link
                        class="btn btn-success btn-lg"
                        to="./about#features"
                        ><span class="x_understandsMore">{{
                          i18n.$t("x_understandsMore")
                        }}</span></router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="rideo-wrap col-12 col-xl-4 aos-init aos-animate"
          data-aos="fade-down"
          data-aos-easing="linear"
        >
          <div class="rideo-box">
            <div class="rideo-top" style="border-top:solid 20px #c4d129;">
              <div class="rideo-bottom" style="border-left:solid 20px #c4d129;">
                <div
                  class="rideo-right"
                  style="border-bottom: 5px solid #c4d129;"
                >
                  <div
                    class="rideo-left"
                    style="border-top: 5px solid #c4d129;"
                  >
                    <div class="memo-text">
                      <div class="rideo-title">
                        <h2>
                          <span class="x_BusinessPhilosophy">{{
                            i18n.$t("x_BusinessPhilosophy")
                          }}</span>
                        </h2>
                      </div>
                      <div class="rideo-content">
                        <p>
                          <span class="x_BusinessPhilosophy_content">{{
                            i18n.$t("x_BusinessPhilosophy_content")
                          }}</span>
                        </p>
                      </div>

                      <router-link
                        class="btn btn-success btn-lg"
                        to="./about#Business_philosophy"
                      >
                        <span class="x_understandsMore">{{
                          i18n.$t("x_understandsMore")
                        }}</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "CoverInformation",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    return { i18n, setLang };
  },
};
/*todo:預計之後使用樣板*/
</script>

<style scoped></style>
