import DBApi from "@/api/DBApi.js";
import store from "@/store/index.js";
export const XDBSettingMixin = {
    data() {
        return {
            db: null,
            GeoKey: null,
            gridAutoGetList: true,
            SearchShow: true,
            GridShow: true,
            InputShow: false,
            isView: false,
            Columns: [],
            datalist: [],
            querydata: {},
            editdata: {},
            TableName: "",
            editTableName: "",
            PrimKey: "id",
        };
    },
    created() {
        //資料處理
        if (!this.editTableName) {
            this.editTableName = this.TableName;
        }
        this.RaiseInputData();
        this.db = new DBApi(process.env.VUE_APP_API, this.Token);
    },
    mounted() {

    },
    methods: {
        RaiseInputData() {
            for (var i in this.Columns) {
                var c = this.Columns[i];
                if (c.isSearch) {
                    //需配合div_Search
                    this.querydata[c.datafield] = "";
                    //   this.SearchInputs.push(c);
                }
                if (c.isInput) {
                    //配合div_Inputs
                    this.editdata[c.datafield] = "";
                    //   this.inputs.push(c);
                }
                if (
                    c.datafield == "geo" ||
                    c.datafield == "geom" ||
                    c.datafield.toLowerCase() == "shape"
                ) {
                    //SQLSERVER
                    //   new GisApi(this);
                    this.GeoKey = c.datafield;
                }
                // RaiseInputData(this, c);
            }
        },
        getFilterbyObj(qData) {
            let filter = "1=1";

            for (let key in qData) {
                let v = qData[key];
                if (v != undefined && v != "") {
                    if (key.indexOf("__from") > 0) {
                        if (!+v) {
                            v = new Date(v); //解決日期查詢問題
                            v = v.toDateString();
                        }
                        filter =
                            filter +
                            " and " +
                            key.replace("__from", "") +
                            ">=" +
                            (+v ? v : "'" + v + "'");
                    } else if (key.indexOf("__to") > 0) {
                        if (v) {
                            v = new Date(v); //解決日期查詢問題
                            v = v.toDateString();
                        }
                        filter =
                            filter +
                            " and " +
                            key.replace("__to", "") +
                            "<=" +
                            (+v ? v : "'" + v + "'");
                    } else if (key.toLowerCase() === "keyword") {
                        //關鍵字

                        let cols = scope.Columns.filter(function(item) {
                            return !item.hidden && item.datafield !== "Action";
                        }).map(function(item) {
                            return item.datafield;
                        });
                        let keywordFilter = cols.reduce(function(sum, item) {
                            return sum + " or " + item + " like '%" + v + "%'";
                        }, cols[0] + " like '%" + v + "%'");

                        filter = filter + " and (" + keywordFilter + ")";
                    } else {
                        //尚未在此解決日期問題
                        filter = filter + " and " + key + "=" + "'" + v + "'";
                    }
                }
            }
            return filter;
        },

        ClearQuery() {
            for (let key in this.querydata) {
                this.querydata[key] = "";
            }
        },
        CancelEdit() {
            this.SearchShow = true;
            this.InputShow = false;
            this.GridShow = true;
            this.isView = false;

            for (let key in this.editdata) {
                this.editdata[key] = "";
            }
        },
        RowDel(index) {
            const fn = (d) => {
                if (d.isSuccess) {
                    alert("刪除成功");
                    this.getDataList();
                } else {
                    alert(d.ErrorMsg);
                }
            };

            //=====先檢查是否有用chekbox，可多筆刪除============
            const delData = [];
            for (let i in this.datalist) {
                let ob = this.datalist[i];
                if (ob.chk) {
                    delData.push(ob[this.PrimKey]);
                }
            }

            if (delData.length > 0) {
                if (!confirm("確定要刪除已選取共" + delData.length + "筆資料嗎?"))
                    return false;
                this.db.DeleteRows(this.editTableName, this.PrimKey, delData, fn);
                return;
            }
            if (index < 0) {
                alert("請選擇資料");
                return;
            }

            if (!confirm("確定要刪除此筆資料嗎?")) return false;

            this.db.DeleteData(
                this.editTableName,
                this.PrimKey,
                this.datalist[index][this.PrimKey],
                fn
            );
        },
        RowView(index) {
            if (index < 0) {
                alert("請選擇資料");
                return;
            }

            this.Columns.forEach((item, i) => {
                //覆置一份
                if (item.ControlType == "SelectCheckBoxs") {
                    //轉換成陣列
                    this.editdata[item.datafield] = JSON.parse(
                        this.datalist[index][item.datafield]
                    );
                } else {
                    this.editdata[item.datafield] = this.datalist[index][item.datafield];
                } //日期格式需額外處理
                if (
                    item.ControlType != null &&
                    item.ControlType.toLowerCase().indexOf("date") >= 0
                ) {
                    this.editdata[item.datafield] = new Date(
                        this.datalist[index][item.datafield]
                    ).toDateString();
                }
            });
            this.isView = true;

            this.Modify_init(false);
        },
        RowEdit(data) {
            if (!data) {
                alert("請選擇資料");
                return;
            }

            this.Columns.forEach((item, i) => {
                //覆置一份
                if (item.ControlType == "SelectCheckBoxs") {
                    //轉換成陣列
                    this.editdata[item.datafield] = JSON.parse(data[item.datafield]);
                } else {
                    this.editdata[item.datafield] = data[item.datafield];
                } //日期格式需額外處理
                if (
                    item.ControlType != null &&
                    item.ControlType.toLowerCase().indexOf("date") >= 0
                ) {
                    this.editdata[item.datafield] = new Date(data[item.datafield])
                        .toLocaleDateString("tw", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        })
                        .replace(/\//g, "-");
                }
            });
            this.isView = false;

            this.Modify_init(false);
        },
        AddNewData() {
            for (let key in this.editdata) {
                this.editdata[key] = "";
            }

            this.isView = false;
            //如果有預設值，手動填入

            this.Modify_init(true);
        },
        Modify_init(isAdd) {
            this.InputShow = true;
            this.SearchShow = false;
            this.GridShow = false;
            let input = document.querySelectorAll("input[type=file]");
            input.forEach((node) => {
                node.value = null;
            });

            //===============================

            //=====可在這設定不可編輯的欄位===
            if (isAdd) {
                //代表要新增, 新增時，所有欄位應該都可以編輯
            } else {
                //修改時，可以指定欄位不能編輯
            }
        },
        UploadFiles(d, fn) {
            let pid = "";

            if (this.editdata[this.PrimKey] == undefined) {
                pid = d.Result[0];
            } else {
                pid = this.editdata[this.PrimKey];
            }

            let files = document.querySelectorAll(`input[type="file"]`);
            let allFile = [];
            for (let i = 0; i < files.length; i++) {
                if (files[i].files.length > 0) {
                    let file = files[i].files[0];
                    allFile.push(file);
                }
            }
            if (allFile.length > 0) {
                this.db.UploadFiles(allFile, this.TableName, pid, (ret) => {
                    fn(ret);
                });
            } else {
                fn({ Result: "no data", isSuccess: true });
            }

            return true;
        },
        SaveData(myForm) {
            myForm.preventDefault();
            let isValid = this.validForm();

            //this.myForm.$valid
            if (isValid) {
                if (!this.formatToDB()) return;

                //新版本不論新增或修改，一律都傳primkey; 2020/4/20
                this.db.UpdateTable(
                    this.editTableName,
                    this.editdata,
                    this.PrimKey,
                    (d) => {
                        if (d.isSuccess == false) {
                            //如果有錯誤
                            alert(d.ErrorMsg);
                        } else {
                            this.UploadFiles(d, (r) => {

                                if (r.isSuccess) {
                                    if (this.editdata[this.PrimKey]) {
                                        alert("修改成功");
                                    } else {
                                        //====================
                                        alert("新增成功");
                                    }
                                } else {
                                    alert(
                                        "上傳失敗，請使用編輯功能，重新上傳。錯誤訊息:" + r.ErrorMsg
                                    );
                                }
                                this.getDataList();
                                this.CancelEdit();
                            });
                        }
                    }
                );
            }
        },
        fileChanged(dom) {
            var ele = dom.target;
            var scp = this;
            var file = ele.files[0];
            scp.editdata[ele.name] = file.name;
            if (file != undefined) {
                if (file.size > 200000000) {
                    alert("檔案大小:限200MB");
                    file.value = "";
                    return false;
                } else {
                    var validExtensions = new Array();
                    var extension = file.name
                        .substring(file.name.lastIndexOf(".") + 1)
                        .toLowerCase();
                    validExtensions[0] = "doc";
                    validExtensions[1] = "docx";
                    validExtensions[2] = "pdf";
                    validExtensions[3] = "jpg";
                    validExtensions[4] = "jpeg";
                    validExtensions[5] = "png";
                    validExtensions[6] = "mp4";
                    validExtensions[7] = "avi";

                    for (var i = 0; i < validExtensions.length; i++) {
                        if (extension == validExtensions[i]) {
                            return true;
                        }
                    }

                    alert("檔案格式：限doc、docx、pdf、jpg、jpeg、png、mp4、avi");
                    file.value = "";
                    return false;
                }
            }
        },
        openfile(id) {
            document.querySelector(`#${id}`).click();
        },
        validForm() {
            try {} catch (error) {
                alert(error);
                return false;
            }

            return true;
        },
        formatToDB() {
            if (!this.editdata[this.PrimKey]) {
                this.db.async = false;
                this.db.async = true;
            }
            return true;
        },
    },
};