<template>
  <!-- 成功案例 -->
  <section id="demo">
    <div class="container">
      <div class="row title-group">
        <div class="col-lg-12">
          <h2 class="title color-angle">
            <span class="x_BusinessSuccessStory">{{
              i18n.$t("x_BusinessSuccessStory")
            }}</span>
          </h2>
          <p class="sub-title color-dust">
            <span class="x_BusinessSuccessStory_content">{{
              i18n.$t("x_BusinessSuccessStory_content")
            }}</span>
          </p>
        </div>
      </div>
      <!-- Swiper -->
      <div class="swiper-all" data-aos="fade-down" data-aos-easing="linear">
        <div class="swiper-container gallery-top">
          <div class="swiper-wrapper">
            <a
              class="swiper-slide"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/section-project/project-01.jpg') +
                  ')',
              }"
              :href="
                require('../../assets/images/section-project/project-01.jpg')
              "
              data-lightbox="example-set"
              :data-title="i18n.$t('x_IntelligentFloodControl')"
            >
              <div class="project-title">
                <h5>
                  <span class="x_IntelligentFloodControl">{{
                    i18n.$t("x_IntelligentFloodControl")
                  }}</span>
                </h5>
                <p>
                  <span class="x_IntelligentFloodControl_content">{{
                    i18n.$t("x_IntelligentFloodControl_content")
                  }}</span>
                </p>
              </div>
            </a>
            <a
              class="swiper-slide"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/section-project/project-03.jpg') +
                  ')',
              }"
              :href="
                require('../../assets/images/section-project/project-03.jpg')
              "
              data-lightbox="example-set"
              :data-title="i18n.$t('x_HechuanBureauRiver')"
            >
              <div class="project-title">
                <h5>
                  <span class="x_HechuanBureauRiver">{{
                    i18n.$t("x_HechuanBureauRiver")
                  }}</span>
                </h5>
                <p>
                  <span class="x_HechuanBureauRiver_content">{{
                    i18n.$t("x_HechuanBureauRiver_content")
                  }}</span>
                </p>
              </div>
            </a>
            <a
              class="swiper-slide"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/section-project/project-02.jpg') +
                  ')',
              }"
              :href="
                require('../../assets/images/section-project/project-02.jpg')
              "
              data-lightbox="example-set"
              :data-title="i18n.$t('x_HotAreaEstimation')"
            >
              <div class="project-title">
                <h5>
                  <span class="x_HotAreaEstimation">{{
                    i18n.$t("x_HotAreaEstimation")
                  }}</span>
                </h5>
                <p>
                  <span class="x_HotAreaEstimation_content">{{
                    i18n.$t("x_HotAreaEstimation_content")
                  }}</span>
                </p>
              </div>
            </a>
            <a
              class="swiper-slide"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/section-project/project-04.jpg') +
                  ')',
              }"
              :href="
                require('../../assets/images/section-project/project-04.jpg')
              "
              data-lightbox="example-set"
              :data-title="i18n.$t('x_SideDitchManagement')"
            >
              <div class="project-title">
                <h5>
                  <span class="x_SideDitchManagement">{{
                    i18n.$t("x_SideDitchManagement")
                  }}</span>
                </h5>
                <p>
                  <span class="x_SideDitchManagement_content">{{
                    i18n.$t("x_SideDitchManagement_content")
                  }}</span>
                </p>
              </div>
            </a>
            <a
              class="swiper-slide"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/section-project/project-05.jpg') +
                  ')',
              }"
              :href="
                require('../../assets/images/section-project/project-05.jpg')
              "
              data-lightbox="example-set"
              :data-title="i18n.$t('x_WasteQuickClearing')"
            >
              <div class="project-title">
                <h5>
                  <span class="x_WasteQuickClearing">{{
                    i18n.$t("x_WasteQuickClearing")
                  }}</span>
                </h5>
                <p>
                  <span class="x_WasteQuickClearing_content">{{
                    i18n.$t("x_WasteQuickClearing_content")
                  }}</span>
                </p>
              </div>
            </a>
          </div>
          <!-- Add Pagination -->
          <!--<div class="swiper-pagination"></div>-->
          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>

        <div class="swiper-container gallery-thumbs">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/section-project/project-01.jpg') +
                  ')',
              }"
            ></div>
            <div
              class="swiper-slide"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/section-project/project-03.jpg') +
                  ')',
              }"
            ></div>
            <div
              class="swiper-slide"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/section-project/project-02.jpg') +
                  ')',
              }"
            ></div>
            <div
              class="swiper-slide"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/section-project/project-04.jpg') +
                  ')',
              }"
            ></div>
            <div
              class="swiper-slide"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/section-project/project-05.jpg') +
                  ')',
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "CoverInformation",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    return { i18n, setLang };
  },
};
</script>

<style scoped>

</style>
