<template>
  <div>
    <hr />
    <footer>
      <p>
        <span class="x_YuShengTechnology">{{
          i18n.$t("x_YuShengTechnology")
        }}</span>
      </p>
      <p>
        <span>Copyright © </span
        ><span class="x_Copyright">{{ i18n.$t("x_Copyright") }}</span>
      </p>
      <p>
        <span class="x_Address">{{ i18n.$t("x_Address") }}</span>
      </p>
      <div id="GoTop"></div>
    </footer>
  </div>
</template>

<script>
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "CoverInformation",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    return { i18n, setLang };
  },
};
</script>

<style scoped></style>
