<template>
  <!-- 模組功能 -->

    <section id="sdk">
        <div class="container" id="XGIS_Developer_Tool_Kit">
            <h1 class="title color-dark text-center">
                <span class="x_Product">{{i18n.$t("x_Product")}}</span>
            </h1>
        </div>
    </section>

        <div class="container">
            <div class="row container_sdk d-flex">
                <div class="col-lg-4 col-md-6 col-sm-12 card f-1 accordion" data-aos="zoom-in" data-aos-duration="1200" data-aos-easing="linear">
                    <img :src="require('../../assets/images/minXGISMap3.jpg')" alt="{{i18n.$t('x_productGEO')}}" style="width:100%; padding:10px" class="productCard">
                    <div class="container my-3">
                        <h3 class="d-flex justify-content-center mb-3"><b class="x_productGEO">{{i18n.$t("x_productGEO")}}</b></h3>
                        <div id="productGEO">
                            <div class="card">
                                <div class="card-header">
                                    <button class="collapsed card-link x_productGEO_xgis" data-bs-toggle="collapse" href="#collapseOneGEO">
                                        {{i18n.$t("x_productGEO_xgis")}}
                                    </button>
                                </div>
                                <div id="collapseOneGEO" class="collapse" data-parent="#productGEO">
                                    <div class="card-body card-body_skd">
                                        <span class="x_intro">{{i18n.$t("x_intro")}}</span><br />
                                        <p class="x_productGEO_xgis_content">
                                            {{i18n.$t("x_productGEO_xgis_content")}}
                                        </p>
                                        <br /><br /><span class="x_service">{{i18n.$t("x_service")}}</span><br />
                                        <p class="x_productGEO_xgis_service1">{{i18n.$t("x_productGEO_xgis_service1")}}</p>
                                        <p class="x_productGEO_xgis_service2">{{i18n.$t("x_productGEO_xgis_service2")}}</p>
                                        <a class="x_understandsMore" target="_blank" href="http://www.xgis.com.tw/xgishome/" style="color:rgb(230, 90, 48);text-decoration: none;">{{i18n.$t("x_understandsMore")}}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <button class="collapsed card-link x_productGEO_ESRI" data-bs-toggle="collapse" href="#collapseTwoGEO">
                                        {{i18n.$t("x_productGEO_ESRI")}}
                                    </button>
                                </div>
                                <div id="collapseTwoGEO" class="collapse" data-parent="#productGEO">
                                    <div class="card-body">
                                        <span class="x_intro">{{i18n.$t("x_intro")}}</span><br />
                                        <p class="x_productGEO_ESRI_content">
                                            {{i18n.$t("x_productGEO_ESRI_content")}}
                                        </p>
                                        <br /><br /><span class="x_service">{{i18n.$t("x_service")}}</span><br />
                                        <p class="x_productGEO_ESRI_service1">{{i18n.$t("x_productGEO_ESRI_service1")}}</p>
                                        <p class="x_productGEO_ESRI_service2">{{i18n.$t("x_productGEO_ESRI_service2")}}</p>
                                        <p class="x_productGEO_ESRI_service3">{{i18n.$t("x_productGEO_ESRI_service3")}}</p>
                                        <p class="x_productGEO_ESRI_service4">{{i18n.$t("x_productGEO_ESRI_service4")}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <button class="collapsed card-link x_productGEO_OGIS" data-bs-toggle="collapse" href="#collapseThreeGEO">
                                        {{i18n.$t("x_productGEO_OGIS")}}
                                    </button>
                                </div>
                                <div id="collapseThreeGEO" class="collapse" data-parent="#productGEO">
                                    <div class="card-body">
                                        <span class="x_intro">{{i18n.$t("x_intro")}}</span><br />
                                        <p class="x_productGEO_OGIS_content">
                                            {{i18n.$t("x_productGEO_OGIS_content")}}
                                        </p>
                                        <br /><br /><span class="x_service">{{i18n.$t("x_service")}}</span><br />
                                        <p class="x_productGEO_OGIS_service1">{{i18n.$t("x_productGEO_OGIS_service1")}}</p>
                                        <p class="x_productGEO_OGIS_service2">{{i18n.$t("x_productGEO_OGIS_service2")}}</p>
                                        <p class="x_productGEO_OGIS_service3">{{i18n.$t("x_productGEO_OGIS_service3")}}</p>
                                        <p class="x_productGEO_OGIS_service4">{{i18n.$t("x_productGEO_OGIS_service4")}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 card f-1 accordion" data-aos="zoom-in" data-aos-duration="1200" data-aos-easing="linear">
                    <img :src="require('../../assets/images/webDesigning.png')" alt="{{i18n.$t('x_product_WDS')}}" style="width:100%; padding:10px" class="productCard">
                    <div class="container my-3">
                        <h3 class="d-flex justify-content-center mb-3"><b class="x_product_WDS">{{i18n.$t("x_product_WDS")}}</b></h3>
                        <div id="productWeb">
                            <div class="card">
                                <div class="card-header">
                                    <button class="collapsed card-link x_product_WDS_XDB" data-bs-toggle="collapse" href="#collapseOneWeb">
                                        {{i18n.$t("x_product_WDS_XDB")}}
                                    </button>
                                </div>
                                <div id="collapseOneWeb" class="collapse" data-parent="#productWeb">
                                    <div class="card-body">
                                        <span class="x_intro">{{i18n.$t("x_intro")}}</span><br />
                                        <p class="x_product_WDS_XDB_content">
                                            {{i18n.$t("x_product_WDS_XDB_content")}}
                                        </p>
                                        <br /><br /><span class="x_serviceAndApply">{{i18n.$t("x_serviceAndApply")}}</span><br />
                                        <p class="x_product_WDS_XDB_service1">{{i18n.$t("x_product_WDS_XDB_service1")}}</p>
                                        <p class="x_product_WDS_XDB_service2">{{i18n.$t("x_product_WDS_XDB_service2")}}</p>
                                        <p class="x_product_WDS_XDB_service3">{{i18n.$t("x_product_WDS_XDB_service3")}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <button class="collapsed card-link x_product_WDS_XFI" data-bs-toggle="collapse" href="#collapseTwoWeb">
                                        {{i18n.$t("x_product_WDS_XFI")}}
                                    </button>
                                </div>
                                <div id="collapseTwoWeb" class="collapse" data-parent="#productWeb">
                                    <div class="card-body">
                                        <p class="x_product_WDS_XFI_content">
                                            {{i18n.$t("x_product_WDS_XFI_content")}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 card f-1 accordion" data-aos="zoom-in" data-aos-duration="1200" data-aos-easing="linear">
                    <img :src="require('../../assets/images/background-car.jpg')" alt="{{i18n.$t('x_product_LPT')}}" style="width:100%; padding:10px" class="productCard">
                    <div class="container my-3">
                        <h3 class="d-flex justify-content-center mb-3"><b class="x_product_LPT">{{i18n.$t("x_product_LPT")}}</b></h3>
                        <div id="productPlate">
                            <div class="card">
                                <div class="card-header">
                                    <button class="collapsed card-link x_product_LPT_XLPR" data-bs-toggle="collapse" href="#collapseOnePlate">
                                        {{i18n.$t("x_product_LPT_XLPR")}}
                                    </button>
                                </div>
                                <div id="collapseOnePlate" class="collapse" data-parent="#productPlate">
                                    <div class="card-body">
                                        <span class="x_intro">{{i18n.$t("x_intro")}}</span><br />
                                        <p class="x_product_LPT_XLPR_content">
                                            {{i18n.$t("x_product_LPT_XLPR_content")}}
                                        </p>
                                        <br /><br /><span class="x_service">{{i18n.$t("x_service")}}</span><br />
                                        <p class="x_product_LPT_XLPR_service1">{{i18n.$t("x_product_LPT_XLPR_service1")}}</p>
                                        <p class="x_product_LPT_XLPR_service2">{{i18n.$t("x_product_LPT_XLPR_service2")}}</p>
                                        <p class="x_product_LPT_XLPR_service3">{{i18n.$t("x_product_LPT_XLPR_service3")}}</p>
                                        <p class="x_product_LPT_XLPR_service4">{{i18n.$t("x_product_LPT_XLPR_service4")}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <button class="collapsed card-link x_product_LPT_ICM" data-bs-toggle="collapse" href="#collapseTwoPlate">
                                        {{i18n.$t("x_product_LPT_ICM")}}
                                    </button>
                                </div>
                                <div id="collapseTwoPlate" class="collapse" data-parent="#productPlate">
                                    <div class="card-body">
                                        <span class="x_intro">{{i18n.$t("x_intro")}}</span><br />
                                        <p class="x_product_LPT_ICM_content">
                                            {{i18n.$t("x_product_LPT_ICM_content")}}
                                        </p>
                                        <br /><br /><span class="x_service">{{i18n.$t("x_service")}}</span><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--
            <div class="row">
                <div class="col-lg-12 text-center">
                    <a class="btn btn-success btn-lg" href="http://www.ysong.com.tw/xgishome/" target="_blank"><span class="x_understandsMore">了解更多</span></a>
                </div>
            </div>
            -->
        </div>
</template>

<script>
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "CoverInformation",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    return { i18n, setLang };
  },
};
</script>

<style scoped>
    
</style>