function InheritDBApi(db, APIurl, m_Token) {
    db.APIurl = APIurl;
    db.Token = m_Token;


    db.getTokenAsync = function (UserID, UserPWD, fn) {
        AjaxQueryAsync(db.APIurl + 'GetToken', {
            UserId: UserID,
            PassWord: UserPWD
        }, function (data) {

            if (data.isSuccess) {
                Token = data.Result;
            }
            fn(data);
        });
    }
    db.SelectTableAsync = function (TableName, Filter, OrderBy, fn) {
        if (TableName == undefined || TableName == null) {
            fn(null);
            return;
        }
        var Url = db.APIurl + "SelectTable";
        AjaxQueryAsync(Url, {
            Token: db.Token,
            TableName: TableName,
            Filter: Filter,
            OrderBy: OrderBy,
            Field: "*",
            DBKey: this.DBKey
        }, fn);
    }

}


function AjaxQuery(url, QueryData, retFunc) {
    $.ajax({
        type: 'GET',
        url: url,
        data: QueryData,
        dataType: "json",
        success: function (data) {
            if (QueryData !== null) {
                retFunc(data, QueryData)
            } else {
                retFunc(data)
            };
        },
        error: function (e, r, h) {
            if (e.status !== 520)
                alert(' (status:' + r + ' error:' + h + ')');
        }
    });
}


function AjaxQueryAsync(url, QueryData, retFunc) {
    console.log(QueryData);
    $.ajax({
        type: 'GET',
        url: url,
        data: QueryData,
        dataType: "json",
        async: false,
        success: function (data) {
            if (QueryData !== null) {
                retFunc(data, QueryData)
            } else {
                retFunc(data)
            };
        },
        error: function (e, r, h) {
            if (e.status !== 520)
                alert(' (status:' + r + ' error:' + h + ')');
        }
    });
}
function AjaxPostAsync(url, QueryData, retFunc) {
    console.log(QueryData);
    $.ajax({
        type: 'Post',
        url: url,
        data: QueryData,
        dataType: "json",
        async: false,
        success: function (data) {
            if (QueryData !== null) {
                retFunc(data, QueryData)
            } else {
                retFunc(data)
            };
        },
        error: function (e, r, h) {
            if (e.status !== 520)
                alert(' (status:' + r + ' error:' + h + ')');
        }
    });
}
