<template>
<div class="navbar navbar-inverse navbar-fixed-top">
    <div class="container">
    <div class="navbar-header">
        <!-- Navigation -->
        <nav id="navbar_id" class="navbar fixed-top navbar-dark navbar-expand-xl " role="navigation">
            <div class="container">
                <!-- Brand and toggle get grouped for better mobile display -->
                <a class="navbar-brand" href="#">
                    <img :src="require('@/assets/images/top.jpg')" width="50" height="50" class="d-inline-block align-top mr-5" alt="{{i18n.$t('x_companyName')}}">
                    <div class="navbar-brand x_companyName" style="display:inline-block ;margin-left: 15px;">{{i18n.$t('x_companyName')}}</div>
                </a>
                <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse " id="bs-example-navbar-collapse-1">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            <a class="btn-target nav-link" data-target="#news"><span class="x_News">{{i18n.$t('CreateNewsBtn')}}</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="btn-target nav-link" data-target="#about"><span class="x_About">{{i18n.$t('CreateAboutBtn')}}</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="btn-target nav-link" data-target="#service"><span class="x_ServiceItems">{{i18n.$t('CreateServiceBtn')}}</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="btn-target nav-link" data-target="#demo"><span class="x_SuccessCase">{{i18n.$t('CreateCaseBtn')}}</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="btn-target nav-link" data-target="#sdk"><span class="x_Product">{{i18n.$t('CreateProductBtn')}}</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="btn-target nav-link" data-target="#map"><span class="x_ServiceExperience">{{i18n.$t('CreateExperienceBtn')}}</span></a>
                        </li>
                        <li class="nav-item">
                            <!--<a class="btn-target" data-target="#type">應用實例</a>-->
                            <a class="btn-target nav-link" data-target="#type"><span class="x_ApplicationRange">{{i18n.$t('CreateSolutionsBtn')}}</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="btn-target nav-link" data-target="#sign"><span class="x_Contact">{{i18n.$t('CreateContactBtn')}}</span></a>
                        </li>
                        <li class="nav-item d-none d-sm-none d-xl-block ms-5">
                            <div class="btn-group dropdown">
                                <button type="button" class="btn btn-info btn-secondary dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-globe-africa"></i><span style="margin-left:10px" class="x_Language">{{i18n.$t('x_Language')}}</span>
                                </button>
                                <ul class="dropdown-menu bg-info">
                                    <li><a class="dropdown-item" value="tw" v-on:click="setLang('tw')"><span class="x_TraditionalChinese">繁體中文</span></a></li>

                                    <!--                <div class="dropdown-divider"></div>-->
                                    <li><a class="dropdown-item" value="en" v-on:click="setLang('en')"><span class="x_English">English</span></a></li>

                                </ul>
                            </div>
                        </li>

                        <li class="nav-item d-block d-xl-none ms-0 dropdown">
                            <div class="dropdown-divider"></div>
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                <i class="fas fa-globe-africa"></i><span class="x_Language" style="margin-left: 8px;">{{i18n.$t('x_Language')}}</span>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" value="tw" v-on:click="setLang('tw')"><span class="x_TraditionalChinese">繁體中文</span></a>
                                <a class="dropdown-item" value="en" v-on:click="setLang('en')"><span class="x_English">English</span></a>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- /.navbar-collapse -->
            </div>
            <!-- /.container -->
        </nav>
    </div>
    </div>
</div>
</template>
<script>
 import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "CoverInformation",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    return { i18n, setLang };
  },
};
</script>
<style scoped>
</style>