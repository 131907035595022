function button_box(color, title, content, target, orderObj) {
    data =
        "<div class='rideo-wrap col-12 col-xl-4' data-aos='fade-down'  data-aos-easing='linear'>" +
        "<div class='rideo-box'>"
        + "<div class='rideo-top' style='border-top:solid 20px " + color + ";'>"
        + "<div class='rideo-bottom' style='border-left:solid 20px " + color + ";'>"
        + "<div class='rideo-right' style='border-bottom: 5px solid " + color + ";'>"
        + "<div class='rideo-left' style='border-top: 5px solid " + color + ";'>"
        + "<div class='memo-text'>"
        + "<div class='rideo-title'>"
        + "<h2>" + title + "</h2>"
        + "</div>"
        + "<div class='rideo-content'>"
        + "<p>" + content + "</p>"
        + "</div>"
        + orderObj
        + "</div>"
        + "</div>"
        + "</div>"
        + "</div>"
        + "</div>"
        + "</div>"
        + "</div>";
    $(target).append(data);
}
