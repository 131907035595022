import { createApp } from 'vue'
import App from './Views/App.vue'
import router from './router'
import  store  from './store'
import { useStore } from 'vuex';
const app = createApp(App)
    /*jQuery*/
import jQuery from 'jquery'
window.$ = window.jQuery = jQuery;
/*bootstrap Core Css*/
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/bootstrap-custom.css'
import '@/assets/css/bootstrap-navfix.css'
import '@/assets/css/button_box.css'
import '@/assets/css/common.css'
import '@/assets/js/common.js'
import '@/assets/js/DBApi.js'
import '@/assets/js/MyApi.js'
import "@/assets/css/Site.css"
import "@/assets/css/main.css"
import "@/assets/css/image-effect.css"
import "@/assets/js/Sebobo-jquery.rondell-1.1.0-2-gfc29115/dist/jquery.rondell.min.css"
import '@/assets/js/Sebobo-jquery.rondell-1.1.0-2-gfc29115/dist/jquery.rondell.min.js'
import "@/assets/css/ipad.css"
import '@/assets/js/jquery.blockUI.js'
import "@/assets/css/button_box.css"
import '@/assets/js/button_box.js'
import '@/assets/js/lightbox2-master/dist/css/lightbox.css'
import '@/assets/js/lightbox2-master/dist/js/lightbox.min.js'

/* swiper  */
// import Swiper JS
// import Swiper styles
import '@/assets/css/swiper_custom.css'
import '@/assets/js/swiper_custom.js'

/*動畫*/
import AOS from 'aos'
import 'aos/dist/aos.css'
app.AOS = new AOS.init({
    offset: 220,
    easing: 'ease-in-sine',
    mirror: false
});

app.use(router).use(store).use(jQuery)
app.mount('#app')