<template>
  <!-- 最新消息 -->
  <section id="news">
    <div class="container">
      <div class="row">
        <h3 class="title color-dark">
          <span class="x_News" v-text="i18n.$t('CreateNewsBtn')"></span>
        </h3>
      </div>
      <div class="row">
        <table class="table table-hover">
          <tbody>
            <tr v-for="news in state.News" :key="news.bulletin_id">
              <td style="color:#3784BA">{{ news.bulletin_title }}</td>
              <td>{{ news.bulletin_content }}</td>
              <td>{{ news.bulletin_date.substring(0, 10) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "LatestNews",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    const state = reactive({
      News: [],
    });

    onMounted(async () => {
      const data = await fetch(
        "https://www.ysong.com.tw/ysong/api/db/SelectTable?Token=&TableName=Bulletin_View&Filter=1%3D1&Field=*"
      ).then((rsp) => rsp.json());
      console.log(data.Result);
      state.News = data.Result;
    });

    return {
      state,
      i18n,
      setLang,
    };
  },
};
</script>

<style scoped></style>
