// import { createApp } from 'vue'
// import { createStore } from 'vuex'

// // Create a new store instance.
// const store = createStore({
//     state() {
//         return {
//             lang: null // 存放使用者選用的語系
//         }
//     },
//     mutations: {
//         // 切換語系設定
//         setLang(state, value) {
//             state.lang = value;
//         }
//     },
//     actions: {},
//     modules: {}
// })
// export default store;

import { createStore } from 'vuex';
// 創建 Vuex Store，並指定 state 的類型
const store = createStore({
  state() {
    return {
      lang: localStorage.getItem("setLang") || 'en' // 默認語系設置
    };
  },
  mutations: {
    setLang(state, value) {
      state.lang = value;
    },
  },
});

export default store;



