<template>
  <!-- GIS 服務體驗 -->
  <section id="map">
    <div class="container">
      <div class="row title-group">
        <div class="col-lg-12">
          <h2 class="title color-angle">
            <span class="x_ServiceExperience">{{
              i18n.$t("x_ServiceExperience")
            }}</span>
          </h2>
          <p class="sub-title color-milk">
            <span class="x_ServiceExperience_content">{{
              i18n.$t("x_ServiceExperience_content")
            }}</span>
          </p>
        </div>
      </div>
      <div class="row justify-content-center addipad" id="addipads">
        <!-- <div
          class="col-lg-6 col-12 row no-gutters justify-content-center"
          id="service0"
        >
          <div
            class="ipadFormat"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <div class="ipad">
              <div class="ipad-body">
                <button
                  id="btnGISSvc0"
                  type="button"
                  class="btn btn-info btn-lg"
                  onclick='javascript:window.open("http://www.xgis.com.tw/XGIS_Service/Template/gmap.html?GISDemo")'
                  style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:1000;"
                >
                  <span class="x_startExperience">{{
                    i18n.$t("x_startExperience")
                  }}</span>
                </button>
                <div class="ipad-control">
                  <div class="c-fill">
                    <div class="r-border"><div class="r-fill"></div></div>
                  </div>
                </div>
                <div class="ipad-monitor">
                  <img
                    :src="require('../../assets/images/minXGISMap3.jpg')"
                    style="width:100%;height:100%; "
                  />
                </div>
                <div class="ipad-camera"></div>
              </div>
            </div>
          </div>
          <div class="text-center w-100">
            <div class="map-memo">
              <h3 class="x_ServiceExperience_0" style="color:#fff;">
                {{ i18n.$t("x_ServiceExperience_0") }}
              </h3>
              <p
                class="x_ServiceExperience_0_content"
                style="color:#fff;font-size:14px;"
              >
                {{ i18n.$t("x_ServiceExperience_0_content") }}
              </p>
            </div>
          </div>
        </div> -->
        <div
          class="col-lg-6 col-12 row no-gutters justify-content-center"
          id="service1"
        >
          <div
            class="ipadFormat"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <div class="ipad">
              <div class="ipad-body">
                <button
                  id="btnGISSvc1"
                  type="button"
                  class="btn btn-info btn-lg"
                  @click="FullView('video')"
                  style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:1000;"
                >
                  <span class="x_startExperience">{{
                    i18n.$t("x_startExperience")
                  }}</span>
                </button>
                <div class="ipad-control">
                  <div class="c-fill">
                    <div class="r-border"><div class="r-fill"></div></div>
                  </div>
                </div>
                <div class="ipad-monitor">
                  <img
                    id="GISVideoPlayer"
                    :src="require('../../assets/video/Produce2.gif')"
                    alt="images/title.ico"
                    style="width:100%;height:100%;"
                  />
                </div>
                <div class="ipad-camera"></div>
              </div>
            </div>
          </div>
          <div class="text-center w-100">
            <div class="map-memo">
              <h3 class="x_ServiceExperience_1" style="color:#fff;">
                {{ i18n.$t("x_ServiceExperience_1") }}
              </h3>
              <p
                class="x_ServiceExperience_1_content"
                style="color:#fff;font-size:14px;"
              >
                {{ i18n.$t("x_ServiceExperience_1_content") }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-12 row no-gutters justify-content-center"
          id="service2"
        >
          <div
            class="ipadFormat"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <div class="ipad">
              <div class="ipad-body">
                <button
                  id="btnGISSvc2"
                  type="button"
                  class="btn btn-info btn-lg"
                  onclick='javascript:window.open("https://play.google.com/store/apps/details?id=tw.com.xgis.mobile")'
                  style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:1000;"
                >
                  <span class="x_startExperience">{{
                    i18n.$t("x_startExperience")
                  }}</span>
                </button>
                <div class="ipad-control">
                  <div class="c-fill">
                    <div class="r-border"><div class="r-fill"></div></div>
                  </div>
                </div>
                <div class="ipad-monitor">
                  <img
                    :src="require('../../assets/images/unnamed.jpg')"
                    style="width:100%;height:100%; "
                  />
                </div>
                <div class="ipad-camera"></div>
              </div>
            </div>
          </div>
          <div class="text-center w-100">
            <div class="map-memo">
              <h3 class="x_ServiceExperience_2" style="color:#fff;">
                {{ i18n.$t("x_ServiceExperience_2") }}
              </h3>
              <p
                class="x_ServiceExperience_2_content"
                style="color:#fff;font-size:14px;"
              >
                {{ i18n.$t("x_ServiceExperience_2_content") }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-12 row no-gutters justify-content-center"
          id="service3"
        >
          <div
            class="ipadFormat"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <div class="ipad">
              <div class="ipad-body">
                <button
                  id="btnGISSvc3"
                  type="button"
                  class="btn btn-info btn-lg"
                  onclick='javascript:window.open("https://ysong.com.tw/Ysong/WEBLPR.html")'
                  style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:1000;"
                >
                  <span class="x_startExperience">{{
                    i18n.$t("x_startExperience")
                  }}</span>
                </button>
                <div class="ipad-control">
                  <div class="c-fill">
                    <div class="r-border"><div class="r-fill"></div></div>
                  </div>
                </div>
                <div class="ipad-monitor">
                  <img
                    :src="require('../../assets/images/car.png')"
                    style="width:100%;height:100%;
                  "
                  />
                </div>
                <div class="ipad-camera"></div>
              </div>
            </div>
          </div>
          <div class="text-center w-100">
            <div class="map-memo">
              <h3 class="x_ServiceExperience_3" style="color:#fff;">
                {{ i18n.$t("x_ServiceExperience_3") }}
              </h3>
              <p
                class="x_ServiceExperience_3_content"
                style="color:#fff;font-size:14px;"
              >
                {{ i18n.$t("x_ServiceExperience_3_content") }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-12 row no-gutters justify-content-center"
          id="service4"
        >
          <div
            class="ipadFormat"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <div class="ipad">
              <div class="ipad-body">
                <button
                  id="btnGISSvc4"
                  type="button"
                  class="btn btn-info btn-lg"
                  onclick='javascript:window.open("https://www.ysong.com.tw/XGIS/build/Arcgis/sceneview.html")'
                  style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:1000;"
                >
                  <span class="x_startExperience">{{
                    i18n.$t("x_startExperience")
                  }}</span>
                </button>
                <div class="ipad-control">
                  <div class="c-fill">
                    <div class="r-border"><div class="r-fill"></div></div>
                  </div>
                </div>
                <div class="ipad-monitor">
                  <img
                    :src="require('../../assets/images/pipeline3D.png')"
                    style="width:100%;height:100%; "
                  />
                </div>
                <div class="ipad-camera"></div>
              </div>
            </div>
          </div>
          <div class="text-center w-100">
            <div class="map-memo">
              <h3 class="x_ServiceExperience_4" style="color:#fff;">
                {{ i18n.$t("x_ServiceExperience_4") }}
              </h3>
              <p
                class="x_ServiceExperience_4_content"
                style="color:#fff;font-size:14px;"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "@/language/i18nPlugin";
// import { store } from "@/store";
import { useStore } from 'vuex';
export default {
  name: "ServiceExperience",
  setup() {
    const store = useStore(); // 使用 useStore 獲取 Vuex store 實例
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit("setLang", value);
      localStorage.setItem("setLang", value);
      i18n.locale.value = value;
    };
    //全畫面撥放影片onclick事件 2016/9/14 by jay
    function FullView(type) {
      //防呆
      if ($("#videoBG").length) {
        return;
      }
      $(document).on("click","#close", function() {
        //刪除物件
        $.unblockUI();
      });
      //取得畫面長寬
      var h = $(window).height();
      var w = $(window).width();
      var navH = $("nav").height();
      //設定全銀幕背景

      var VideoPlayer = "";

      if (type == "video") {
        //暫停播放展示影片
        //$("#GISVideoPlayer").get(0).pause();
        if (w > 736) {
          VideoPlayer += //"<img src='images/X.png' style='position:absolute;right:0;top:-30px;width:30px;height:30px;' onclick='CloseVideo()'>"
            "<video id='FullView' autoplay controls style='width:100%; display: block;'>" +
            '<source src="' +
            require("@/assets/video/Produce2_Big.mp4") +
            "\" type='video/mp4' />" +
            "您的瀏覽器不支援此 HTML5 影片標籤" +
            "</video>";
        } else {
          VideoPlayer +=
            '<img id="close" src="' +
            require("@/assets/images/X.png") +
            "\" style='position:absolute;right:0;top:-30px;width:30px;height:30px;'>" +
            "<video id='FullView' autoplay controls style='width:" +
            w * 0.8 +
            "px; display: block;'>" +
            '<source src="' +
            require("@/assets/video/Produce2_Big.mp4") +
            "\" type='video/mp4' />" +
            "您的瀏覽器不支援此 HTML5 影片標籤" +
            "</video>";
        }
      } else if (type == "picture") {
        VideoPlayer +=
          "<img id='FullView' src='" +
          require("@/assets/images/XGISMap3.jpg") +
          "' width='80%' height='auto'></img>";
      }

      VideoPlayer += "</div>";
      //加入Jquery套件

      if (w > 736) {
        $.blockUI({
          message: VideoPlayer,
          css: {
            top: h / 2 - h * 0.2 + (w > h ? -50 : 40) + "px",
            left: "25%",
            width: "auto",
          },
        });
      } else {
        $.blockUI({
          message: VideoPlayer,
          css: {
            top: h / 2 - h * 0.2 + (w > h ? -50 : 40) + "px",
            left: (w / 2) * 0.2 + "px",
            width: "auto",
          },
        });
      }

      $(".blockOverlay").click($.unblockUI);
      var doit;
      //當畫面改變時調整背景大小
      $(window).on("resize", function(e) {
        //防呆
        if ($("#FullView").length == 0) {
          return;
        }
        clearTimeout(doit);
        doit = setTimeout(function() {
          var rh = $(window).height();
          var rw = $(window).width();

          if (w > 736) {
            $.blockUI({
              message: VideoPlayer,
              css: {
                top: rh / 2 - rh * 0.2 + (rw > rh ? -50 : 40) + "px",
                left: rw + "px",
                width: "auto",
                cursor: "auto",
              },
            });
          } else {
            $.blockUI({
              message: VideoPlayer,
              css: {
                top: rh / 2 - rh * 0.2 + (rw > rh ? -50 : 40) + "px",
                left: (rw / 2) * 0.2 + "px",
                width: "auto",
                cursor: "auto",
              },
            });
          }

          $("#FullView").width(rw * 0.8 + "px");
          $(".blockOverlay").click($.unblockUI);
        }, 100);
      });
    }
    return { i18n, setLang, FullView };
  },
};
//todo:有空拿common.js的PlayerList改寫成v-for數據
</script>

<style scoped></style>
