import {
    createWebHistory,
    createWebHashHistory,
    createRouter
} from "vue-router";


import index from "@/Views/index.vue";
import AboutYsong from "@/Views/AboutYsong.vue";
import StreetLight from "@/Views/StreetLight.vue";
import store from "@/store";
const routes = [
    {
        path: "/",
        component: index,
    },
    {
        path: "/about",
        component: AboutYsong,
    },
    {
        path: "/streetlight",
        name: 'StreetLight',
        component: StreetLight,
    },
];
const router = createRouter({
    history: createWebHashHistory("YsongVue3"),
    //history: createWebHistory("YsongVue3"),
    routes,
});
export default router;