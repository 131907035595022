$(function() {

    //2016/09/14 Jay新增
    //================================================================================================
    //要播放的檔案
    var PlayerList = [];
    //PlayerList.push({ src: "XGISMap3.jpg", title: "2.5維產品地圖導覽", memo: "利用2.5維電子地圖，將本公司服務內容與產品進行描述。通過直觀的地理實景表現方式，同時集成生活資訊、防災資訊、電子商務、導航等一系列服務", ClickTarget: 'FullView("picture")' });
    PlayerList.push({
        src: "minXGISMap3.jpg",
        title: "2.5維產品地圖導覽",
        memo: "利用2.5維電子地圖，將本公司服務內容與產品進行描述。通過直觀的地理實景表現方式，同時集成生活資訊、防災資訊、電子商務、導航等一系列服務",
        ClickTarget: "javascript:window.open(" + '"http://www.xgis.com.tw/XGIS_Service/Template/gmap.html?GISDemo"' + ")",
        className: "<h3 class='x_2.5DProduct'>" + this.title + "</h3>",
        className_memo: "<p class='x_2.5DProduct_content'>" + this.memo + "</p>"
    });
    PlayerList.push({
        src: "Produce2.gif",
        title: "三維地理資訊系統",
        memo: "利用本公司三維電子地圖，進行飛行模擬及展示",
        ClickTarget: 'FullView("video")',
        className: "<h3 class='x_ThreeDimensional'>" + this.title + "</h3>",
        className_memo: "<p class='x_ThreeDimensional_content'>" + this.memo + "</p>"
    });
    PlayerList.push({
        src: "unnamed.jpg",
        title: "行動式APP外業調查系統",
        memo: "本公司外業調系統，可由GOOGLE PLAY 下載試用",
        ClickTarget: "javascript:window.open(" + '"https://play.google.com/store/apps/details?id=tw.com.xgis.mobile"' + ")",
        className: "<h3 class='x_MobileAPPField'>" + this.title + "</h3>",
        className_memo: "<p class='x_MobileAPPField_content'>" + this.memo + "</p>"
    });
    PlayerList.push({
        src: "car.png",
        title: "車牌辨識",
        memo: "可上傳車牌圖片，將影像進行分析與演算之服務",
        ClickTarget: "javascript:window.open(" + '"./WEBLPR.html"' + ")",
        className: "<h3 class='x_LicensePlate'>" + this.title + "</h3>",
        className_memo: "<p class='x_LicensePlate_content_text'>" + this.memo + "</p>"
    });
    PlayerList.push({
        src: "pipeline3D.png",
        title: "三維管線圖台",
        memo: "",
        ClickTarget: "javascript:window.open(" + '"http://www.ysong.com.tw/XGIS/Template/Map3D/sceneview.html"' + ")",
        className: "<h3 class='x_PipeLine3D'>" + this.title + "</h3>",
        className_memo: "<p class='x_PipeLine3D_content'>" + this.memo + "</p>"
    });
    console.log(PlayerList)
        //================================================================================================

    // 背景調整
    initial();
    //選單滑動
    $('.navbar-collapse').on('click', 'a', function(e) { // 監聽 nav 下的每個 a
        e.preventDefault();
        const anchor = $(this).attr('data-target'); // 取得個別點擊時的data-target，就是個別id的區塊
    
        if (anchor) {
            const targetElement = $(anchor);
            if (targetElement.length) {
                const linkScroll = targetElement.offset().top; // 每個區塊的最上方距離 document 最上方有多遠
                $('html,body').stop().animate({ // 加入stop() 讓選單滑動時不用等到動畫全程跑完就可以點選其他選單
                    scrollTop: linkScroll // 43 是此範例 header 的高度，因為 header 設定 fixed 所以扣掉，
                        // 但 zone1 要設定 padding-top 空間才不會被蓋掉
                }, 100);
            } else {
                console.error(`Element with selector ${anchor} not found.`);
            }
        } else {
            console.error('data-target attribute not found.');
        }
    });
    // $('.navbar-collapse').on('click', 'a', function(e) { // 監聽 nav 下的每個 a
    //     e.preventDefault();
    //     const anchor = $(this).attr('data-target'); //取得個別點擊時的data-target，就是個別id的區塊

    //     const linkScroll = $(anchor).offset().top; // 每個區塊的最上方距離 document 最上方有多遠
    //     $('html,body').stop().animate({ // 加入stop() 讓選單滑動時不用等到動畫全程跑完就可以點選其他選單
    //         scrollTop: linkScroll // 43 是此範例 header 的高度，因為 header 設定 fixed 所以扣掉，
    //             // 但 zone1 要設定 padding-top 空間才不會被蓋掉
    //     }, 100)
    // });

    // 地圖應用
    // $('#type .GroupItem').hover(function() {
    //     $(this).children('.GroupContent').animate({
    //         opacity: '1',
    //         width: '100%',
    //         height: '100%'
    //     }, 300);
    // }, function() {
    //     $(this).children('.GroupContent').animate({
    //         opacity: '0',
    //         width: '0%',
    //         height: '0%'
    //     }, 400);
    // });

    // 回到最上方的按鈕
    $('#GoTop').on('click', function(e) {
        e.preventDefault();
        $('html,body').animate({ // 有些瀏覽器只支援html，有些只支援body 所以兩個都寫進去
            scrollTop: 0,
        }, 700)
    }).hover(function() {
        $(this).css('opacity', '1');
    }, function() {
        $(this).css('scale', '1.2');
    });
});


// re initial
$(window).resize(function() {
    initial();
});

// 背景調整(隨螢幕)
function initial() {

    var h = $(window).height();
    var w = $(window).width();

    if (w > h) {
        if (w > 467) {
            //寬螢幕模式

            $('body').css('background-size', w + 'px ' + h + 'px');
            $('#banner').css('height', h);
        } else {
            $('body').css('background-size', 'contain');
            $('#banner').css('height', '768px');
        }
    } else {
        //長螢幕模式

        $('body').css('background-size', (w * 2) + 'px ' + h + 'px');
        $('#banner').css('height', h);
    }
    $('#content').fadeIn();
}

//離開焦點後隱藏下拉選單
//$('.navbar-toggler').focusout(function () {
//    $('.collapse').collapse('hide');
//});

$('.btn-target.nav-link').click(function() {
    $('#bs-example-navbar-collapse-1').collapse('hide');
})

//在語系點選時關閉
$('.dropdown-item').click(function() {
    $('#bs-example-navbar-collapse-1').collapse('hide');
})