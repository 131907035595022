<template>
  <div id="index">
    <nav-menu></nav-menu>
    <CoverInformation></CoverInformation>
    <LatestNews></LatestNews>
    <About></About>
    <Service></Service>
    <Demo></Demo>
    <Model></Model>
    <ServiceExperience></ServiceExperience>
    <ApplicationRange></ApplicationRange>
    <ServiceApplication></ServiceApplication>
    <Footer></Footer>
    <router-view />
  </div>
</template>

<script>
/*  導覽  */
import NavMenu from "@/components/ysong/NavMenu.vue";
/*  封面資訊  */
import CoverInformation from "@/components/ysong/CoverInformation.vue";
/*  最新消息  */
import LatestNews from "@/components/ysong/LatestNews.vue";
/*  關於  */
import About from "@/components/ysong/About.vue";
/*  服務項目  */
import Service from "@/components/ysong/Service.vue";
/*  成功案例  */
import Demo from "@/components/ysong/Demo.vue";
/*  產品服務與工具  */
import Model from "@/components/ysong/Model.vue";
/*  服務體驗  */
import ServiceExperience from "@/components/ysong/ServiceExperience.vue";
/*  應用實例  */
import ApplicationRange from "@/components/ysong/ApplicationRange.vue";
/*  服務申請  */
import ServiceApplication from "@/components/ysong/ServiceApplication.vue";
/*  頁尾  */
import Footer from "@/components/ysong/Footer.vue";


export default {
  name: "index",
  components: {
    NavMenu,
    Footer,
    CoverInformation,
    LatestNews,
    About,
    Service,
    Demo,
    Model,
    ServiceExperience,
    ApplicationRange,
    ServiceApplication,
  },
};
</script>

<style>
body {
  padding-top: 50px;
  padding-bottom: 20px;
}

/* Set padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
  max-width: 280px;
}
.signForm input,
.signForm textarea {
  max-width: 100%;
}
</style>
