<template>
   <router-view></router-view>
</template>

<script>
import store from "@/store";
import { provideI18n } from "@/language/i18nPlugin";
import tw from "@/language/tw";
import en from "@/language/en";
export default {
  name: "App",
  components: {
  },
      setup() {
        console.log(store);
      let locale = 'tw'
      if (localStorage.getItem('setLang')) {
        locale = localStorage.getItem('setLang');
        store.commit('setLang', locale);
      } else {
        store.commit('setLang', locale);
      }
      provideI18n({
        locale: locale,
        messages: {
          'tw': tw,
          'en': en
        }
      })
    }
};
</script>

<style>
  body {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  /* Set padding to keep content from hitting the edges */
  .body-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  /* Set width on the form input elements since they're 100% wide by default */
  input,
  select,
  textarea {
    max-width: 280px;
  }
  .signForm input,
  .signForm textarea {
    max-width: 100%;
  }
</style>
