$(function() {
    var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 5,
        loop: true,
        freeMode: true,
        loopedSlides: 5, //looped slides should be the same
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
    var galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        thumbs: {
            swiper: galleryThumbs,
        },
    });
    var $swiper = $(".gallery-top .swiper-wrapper .swiper-slide");
    var $title = $(".gallery-top .swiper-wrapper .swiper-slide .project-title");
    $swiper.hover(function() {

        $title.animate({
            opacity: 1
        }, 500)
        $swiper.animate({
            opacity: 0.7
        }, 500)

    }, function() {
        $title.animate({
            opacity: 0
        }, 500)
        $swiper.animate({
            opacity: 1
        }, 500)
    });

})